<template>
  <f7-page class="shop-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <div class="floating-video-container">
        <div class="floating-controls">
          <f7-link @click="onFullScreenVideo">
            <font-awesome-icon :icon="['far', 'expand']" fixed-width />
          </f7-link>
          <f7-link @click="onHideVideo">
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </div>

        <template v-for="videoBanner in shopVideoBanners" :key="'svv_' + videoBanner.BannerId">
          <video v-if="videoBanner?.Video" controls muted controlsList="nofullscreen" autoplay>
            <source :src="$h.getVideo(videoBanner?.Video, 'BANNER', null, false)" type="video/mp4" />
            {{ $t.getTranslation('LBL_BROWSER_NO_SUPPORT_VIDEO') }}
          </video>
        </template>
      </div>

      <ProductNavigationComponent :title="$t.getTranslation(pageTitle)" searchname="shop" @searched="onSearch" />

      <div v-if="shopLinkBanners && shopLinkBanners.length > 0" class="link-container">
        <template v-for="linkBanner in shopLinkBanners" :key="'sbl_' + linkBanner.BannerId">
          <a @click="onViewBanner(linkBanner)">
            <img :src="$h.getImage(linkBanner?.Image, 'BANNER', null, false)" alt="Shop Banner Link Image" loading="lazy" />
          </a>
        </template>
      </div>
    </template>

    <section class="shop-container">
      <div class="container">
        <BannerListComponent ref="bannerListComponent" type="PROMOTION" divider="true" :f7route="f7route" :f7router="f7router" />
        <CategoryListComponent ref="categoryListComponent" type="PRODUCT" divider="true" mode="FEATURED" size="10" />
        <CountryListComponent ref="countryListComponent" mode="TOPCOUNTRY" divider="true" :circular="true" :title="$t.getTranslation('LBL_COUNTRY')" url="/shop/country/list/" />
        <ProductListComponent ref="productListFlashSaleComponent" mode="FLASHSALE" :size="25" :isLoader="true" :title="$t.getTranslation('LBL_FLASH_SALE')" layout="SWIPER" />
        <ProductListComponent ref="productListGroupBuyComponent" mode="GROUPBUY" :size="25" :isLoader="true" :title="$t.getTranslation('LBL_GROUP_BUY')" layout="SWIPER" />
        <ProductListComponent ref="productListComponent" mode="FEATURED" :size="6" :title="$t.getTranslation('LBL_FEATURED')" />
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, defineAsyncComponent } from 'vue'
import { extendedConfigs } from '@/utils/configs'

import { Dom7 } from 'framework7'

import { useStore } from '@/store'
import { helpers } from '@/utils/helpers.js'
import { get } from '@/utils/axios'

//import ProductNavigationComponent from '@/components/navigations/ProductNavigationComponent.vue'
//import BannerListComponent from '@/components/BannerListComponent.vue'
///import CategoryListComponent from '@/components/CategoryListComponent.vue'
//import ProductListComponent from '@/components/ProductListComponent.vue'
//import CountryListComponent from '@/components/CountryListComponent.vue'

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue'))
const CategoryListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "category" */ /* webpackMode: "lazy" */ '@/components/CategoryListComponent.vue'))
const ProductListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ /* webpackMode: "lazy" */ '@/components/ProductListComponent.vue'))
const CountryListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "country" */ /* webpackMode: "lazy" */ '@/components/CountryListComponent.vue'))
const BannerListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "banner" */ /* webpackMode: "lazy" */ '@/components/BannerListComponent.vue'))

export default defineComponent({
  name: 'ShopPage',
  components: {
    ProductNavigationComponent,
    BannerListComponent,
    CategoryListComponent,
    ProductListComponent,
    CountryListComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    const productListComponent = ref(false)
    const showPreloader = ref(false)
    const pageTitle = ref('LBL_SHOP')

    const userToken = computed(() => store.getters['user/getData']?.Token)
    const languageCode = computed(() => store.getters['translation/getLanguage'])

    const shopLinkBanners = ref([])
    const shopVideoBanners = ref([])

    if (extendedConfigs?.hideProductPrice) {
      pageTitle.value = 'LBL_WINES'
    }

    onMounted(async () => {
      await getBannerList()
      await getFloatingBannerList()
    })

    const getBannerList = async () => {
      let ret = await get('/banner/list', { Type: 'SHOPLINK', page: 1, size: 6 })

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter]
          shopLinkBanners.value.push(item)
        }
      }
    }

    const getFloatingBannerList = async () => {
      let ret = await get('/banner/list', { Type: 'SHOPVIDEO', page: 1, size: 1 })

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter]
          shopVideoBanners.value.push(item)
        }

        if (shopVideoBanners.value.length > 0) Dom7('.floating-video-container').addClass('in')
      }
    }

    const onSearch = (searchValue) => {
      if (searchValue && searchValue != '') props.f7router.navigate({ name: 'productListPage', query: { search: searchValue } })
    }

    const onViewBanner = (banner) => {
      if (banner.Link) {
        if (banner.Link.indexOf('http') > -1) {
          helpers.deepLinking(props.f7router, banner.Link)
        } else {
          if (banner.Link.indexOf('/') > -1) {
            props.f7router.navigate(banner.Link)
          } else {
            props.f7router.navigate({ name: banner.Link })
          }
        }
      }
    }

    const onFullScreenVideo = () => {
      let videoElement = Dom7('.floating-video-container video')
      videoElement[0].requestFullscreen()
    }

    const onHideVideo = () => {
      Dom7('.floating-video-container').removeClass('in')
    }

    return {
      onSearch,
      productListComponent,
      showPreloader,
      pageTitle,
      userToken,
      shopLinkBanners,
      shopVideoBanners,
      onViewBanner,
      languageCode,
      onFullScreenVideo,
      onHideVideo
    }
  },
  watch: {
    languageCode(oldVal, newVal) {
      this.$refs.categoryListComponent.getData(true)
      this.$refs.bannerListComponent.getData(true)
      this.$refs.countryListComponent.getData(true)
      this.$refs.productListGroupBuyComponent.getData(null, true)
      this.$refs.productListComponent.getData(null, true)
    }
  },
  methods: {
    async loadMore() {
      await this.$refs.productListComponent.loadMore()
    }
  }
})
</script>
